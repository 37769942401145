import React, { createContext, useState } from 'react'
import { Themes } from '../Theme/Theme'

const ThemeContext = createContext(null)

const ThemeProvider = ({children}) => {
  const [theme, setTheme] = useState('LIGHTTHEME')

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      { children }
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }