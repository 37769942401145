import React from 'react';
import styled from 'styled-components';
import DeepButton from '../Buttons/DeepButton';
import { IconRenderer } from '../../assets/Icons';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* border-bottom:
    2px
    solid
    ${props => props.theme.border.colors.primary};
    margin: ${props => props.vertical ? '0 0 0 -2px' : '0  0 0 -2px'}; */
`

const BackButton = styled.button`
  width: ${props => props.theme.buttons.buttonSizeSmall};
  height: ${props => props.theme.buttons.buttonSizeSmall};
  border: 0;
  background: ${props => props.theme.backgroundColors.button};
  color: ${props => props.theme.buttons.buttonTextColor};

  &:active {
    color: ${props => props.theme.buttons.buttonTextActiveColor};
  }
`

const NavInput = styled.input`
  border:
    2px
    solid
    ${props => props.theme.border.colors.primary};
  border-radius: ${props => props.theme.border.radius.secondary};
  padding: 8px;
  width: calc(100% - 92px);
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: 300;

`

const Navigator = ({
  onClickBack = () => {},
  backDisabled = false,
  path=""
}) => {
  return (
    <Container>    
      <BackButton
        onClick={onClickBack}
        disabled={!!backDisabled}
      >
        <i className='fa-solid fa-arrow-left'></i>
      </BackButton>
        <NavInput type="text" value={path} />
      </Container>
  )
}

export default Navigator