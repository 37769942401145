import React, { createContext, useEffect, useState } from 'react'

const AppStateContext = createContext(null)
const LocalStorageKey = 'AppState';
const getAppStateFromLocalStorage = () => {
  return JSON.parse(window.localStorage.getItem(LocalStorageKey)) || {};
}

const AppStateProvider = ({children}) => {
  const [appState, setAppState] = useState(getAppStateFromLocalStorage())

  const saveStateToLocalStorage = (newAppState) => {
    window.localStorage.setItem(LocalStorageKey, JSON.stringify(newAppState));
    // Object.keys(appState).forEach((appStateElement) => {
    //   window.localStorage.setItem(appStateElement);
    // })
  }

  const setAppStateValue = (key, value) => {
    setAppState(oldAppState => {
      const newAppState = {...oldAppState};
      newAppState[key] = value;
      setAppState(newAppState);
      saveStateToLocalStorage(newAppState);
    });
  }

  useEffect(() => {
    const appStateFromLocalStorage = getAppStateFromLocalStorage();
    if (!!appStateFromLocalStorage) {
      setAppState(appStateFromLocalStorage);
    }
  }, []);

  return (
    <AppStateContext.Provider value={{ appState, setAppStateValue }}>
      { children }
    </AppStateContext.Provider>
  )
}

export { AppStateContext, AppStateProvider }