import { Applications } from './applications';

const events = {
  ABOUT: 'about',
  ABOUT_THIS_MAX: 'about_this_max',
  NETWORK: 'network',
  OPEN_WINDOW: 'open_window',
  SHUT_DOWN: 'shut_down',
  SHUT_DOWN_CONFIRM: 'shut_down_confirm',
}

export default events;