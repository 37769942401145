import React from "react";
import styled from "styled-components";
import TitleBarButtons from "./TitleBarButtons";

const TitleBarContainer = styled.div`
  width: 100%;
  height: ${props => props.theme.window.topbar.height};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  background: ${props => props.theme.backgroundColors.window}; 
  font-size: ${props => props.theme.window.topbar.fontSize};
  color: ${props => props.theme.colors.textColor};
`

const TitleBarInfo = styled.div`
  width: 100px;
`

const TitleBarControls = styled.div`
  width: 100px;
  flex-grow: 0;
`

const TitleBarTitle = styled.div`
  display: block;
  width: auto;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  align-items: center;
`

const TitleBarIcon = styled.div`
  margin-right: 8px;
  padding-top: 0;
  display: flex;
  align-items: center;
`;

const TitleBarText = styled.div`
  display: block;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TitleBar = ({title, id, Icon, classProp = "", onClickMaximise, canMaximise}) => {
  return (
    <TitleBarContainer className={classProp}>
      <TitleBarInfo />
      <TitleBarTitle>
        {!!Icon && <TitleBarIcon><Icon width={22} height={22} strokeWidth={2} /></TitleBarIcon>}
        <TitleBarText>{title && title}</TitleBarText>
      </TitleBarTitle>
      <TitleBarControls>
        <TitleBarButtons id={id} onClickMaximise={onClickMaximise} canMaximise={canMaximise} />
      </TitleBarControls>
    </TitleBarContainer>
  )
}

export default TitleBar;