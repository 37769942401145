import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SpriteSheetContainer = styled.div`
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;
`;

// type SpriteAnimatorProps = {
//   spriteSheet: string;
//   frameWidth: number;
//   frameHeight: number;
//   rowNumber: number;
//   fps: number;
// };

const SpriteSheetAnimator = ({ duration, spriteSheet, scale, frameWidth, frameHeight, rowNumber, frameCount, fps, onEndAnimation }) => {
  const [frameIndex, setFrameIndex] = useState(0);
  const animationInterval = 1000 / fps;

  useEffect(() => {
    if (fps === 0) {
      setFrameIndex(0)
    }
    // for single frame animations, end the animation after the duration
    if (!!duration && duration > 0) {
      const timeoutId = setTimeout(() => {
        onEndAnimation();
      }, duration);

      return () => clearTimeout(timeoutId);
    } else if (fps > 0) { // for multi-frame animations, loop through the frames
      const intervalId = setInterval(() => {
        setFrameIndex((prevFrameIndex) => {
          return (prevFrameIndex + 1) % frameCount;
        });
      }, animationInterval);

      return () => clearInterval(intervalId);
    }
  }, [animationInterval, frameCount]);

  // when frame index updates, check if the animation should end
  useEffect(() => {
    if (!duration && duration <= 0 && frameIndex >= frameCount - 1) {
      onEndAnimation();
    }
  }, [frameIndex]);

  const backgroundPositionX = -frameIndex * frameWidth;
  const backgroundPositionY = -rowNumber * frameHeight;

  const spriteStyle = {
    width: frameWidth,
    height: frameHeight,
    backgroundImage: `url(${spriteSheet})`,
    backgroundPosition: `${backgroundPositionX}px ${backgroundPositionY}px`,
    backgroundRepeat: 'no-repeat',
    transform: `scale(${scale})`,
    transformOrigin: 'top left',
  };

  return (
    <SpriteSheetContainer $width={frameWidth * scale} $height={frameHeight * scale}>
      <div style={spriteStyle} />
    </SpriteSheetContainer>
  );
};

export default SpriteSheetAnimator;
