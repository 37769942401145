import React, { useEffect, useState } from 'react';
import Window from '../Window/Window';
import styled from 'styled-components';
import useInterval from '../../hooks/useInterval';
import { IconRenderer, Icons } from '../../assets/Icons';

const PrintContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const AnimationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const PrintTextContainer = styled.div`
  min-width: 70px;
  text-align: left;
`

const PrintingFrames = [
  {
    loadingDots: Icons.LoadingDots0,
    printer: Icons.Printer0,
    printText: 'Printing.'
  },
  {
    loadingDots: Icons.LoadingDots1,
    printer: Icons.Printer1,
    printText: 'Printing..'
  },
  {
    loadingDots: Icons.LoadingDots2,
    printer: Icons.Printer2,
    printText: 'Printing...'
  },
  {
    loadingDots: Icons.LoadingDots3,
    printer: Icons.Printer3,
    printText: 'Printing.'
  },
  {
    loadingDots: Icons.LoadingDots4,
    printer: Icons.Printer4,
    printText: 'Printing..'
  },
  {
    loadingDots: Icons.LoadingDots5,
    printer: Icons.Printer5,
    printText: 'Printing...'
  },
];

const ErrorFrames = [
  {
    computer: Icons.ComputerSad,
    dots: Icons.LoadingDots0,
    printer: Icons.PrinterUpsideDown,
    printText: `❌ ERROR: Printer Upsidedown`
  },
  {
    computer: Icons.ComputerSad,
    dots: Icons.LoadingDots0,
    printer: Icons.Ink,
    printText: `❌ ERROR: Ink suspiciously sufficient`
  },
  {
    computer: Icons.ComputerSad,
    dots: Icons.LoadingDots0,
    printer: Icons.InkBlackTooFull,
    printText: `❌ ERROR: Black ink too full`
  },
  {
    computer: Icons.ComputerSad,
    dots: Icons.LoadingDots0,
    printer: Icons.Toad,
    printText: `❌ ERROR: Your printer is in another castle`
  },
]

const Print = ({windowId, fileName}) => {
  const FrameDelay = 300;
  const ErrorDelay = 3000;
  const DefaultComputerImage = Icons.ComputerIcon;
  const DefaultDotsIcon = Icons.LoadingDots0;
  const DefaultPrinterIcon = Icons.Printer0;

  const [currentFrame, setCurrentFrame] = useState(0);
  const [millisPassed, setMillisPassed] = useState(0);
  const [error, setError] = useState(false);
  const [errorIndex, setErrorIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFrame(oldCurrentFrame => {
        if (oldCurrentFrame >= PrintingFrames.length - 1) {
          return 0
        }
        return oldCurrentFrame + 1
      });
      setMillisPassed(oldMillisPassed => {
        if(oldMillisPassed >= ErrorDelay) {
          clearInterval(interval);
          setErrorIndex(Math.floor(Math.random()*ErrorFrames.length));
          setError(true);
        }
        return oldMillisPassed + FrameDelay
      });
      
      if (currentFrame >= PrintingFrames.length - 1){
        setCurrentFrame(0);
      }
      if (millisPassed >= ErrorDelay) {
        
      }
    }, FrameDelay);
    return () => clearInterval(interval);
  }
  , []);

  const Computer = error ?
    ErrorFrames[errorIndex].computer :
    PrintingFrames[currentFrame].computer || DefaultComputerImage;
  const Dots = error ?
    ErrorFrames[errorIndex].dots :
    PrintingFrames[currentFrame].loadingDots;
  const Printer = error ?
    ErrorFrames[errorIndex].printer :
    PrintingFrames[currentFrame].printer;
  const PrintText = error ?
    ErrorFrames[errorIndex].printText :
    PrintingFrames[currentFrame].printText;

  return (
    <Window
      id={windowId}
      title={'Print - ' + fileName}
      width={360}
      height={250}
      canMaximise={false}
    >
      <PrintContainer>
        <AnimationContainer>
          <IconRenderer Component={Computer} width={82} />
          <IconRenderer Component={Dots} />
          <IconRenderer Component={Printer} />
        </AnimationContainer>
        <PrintTextContainer>
          {PrintText}
        </PrintTextContainer>
      </PrintContainer>
    </Window>
  )
}

export default Print;