import React from 'react';
import styled from 'styled-components';
import Window from '../Window/Window';
import Button from '../Buttons/Button';

const DialogContainer = styled.div`
  background: ${props => props.theme.backgroundColors.window};
  height: 100%;
  width: 100%;
  padding: ${props => props.theme.spacing.medium};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
`

const DialogText = styled.div`
  margin: 0 0 ${props => props.theme.spacing.medium} 0;
`

const DialogButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${props => props.theme.spacing.small};
`

const Dialog = ({
  children = null,
  text = null,
  title = "Dialog",
  primaryCtaText = "Okay",
  primaryAction = null,
  secondaryCtaText = "Cancel",
  secondaryAction = null,
  width = 440,
  height = 140,
  left = null,
  right = null,
  top = null,
  bottom = null,
  windowId,
}) => {
  return(
    <Window
      title={title}
      id={windowId}
      width={width}
      height={height}
      canMaximise={false}
      center
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    >
      <DialogContainer>
        { !!text && <DialogText>{text}</DialogText>}
        { children }
        <DialogButtonContainer>
          { !!primaryAction && <Button onClick={primaryAction}>{primaryCtaText}</Button>}
          { !!secondaryAction && <Button onClick={secondaryAction}>{secondaryCtaText}</Button>}
        </DialogButtonContainer>
      </DialogContainer>
    </Window>
  )
}

export default Dialog;