import { NewDirectories } from '../Data/MockCmsData'

const MapDirectories = (id, directoryItems, path) => {
  directoryItems.map(fileFolder => {
    fileFolder.parent = id;
    fileFolder.path = path + '/' + fileFolder.name;
    if (fileFolder?.data?.items?.length > 0) {
      MapDirectories(fileFolder.id, fileFolder.data.items, fileFolder.path);
    }
  });
  return directoryItems;
}

const Directories = MapDirectories(-1, NewDirectories, 'C:/');

function findDirectoryById(id, directory = Directories) {
  function iter(a) {
    if (a.id === id) {
        result = a;
        return true;
    }
    return Array.isArray(a.data?.items) && a.data.items.some(iter);
  }

  var result;
  directory.some(iter);
  return result;
}

//returns an array of the path to the directory from the root
function findPathById(id, directories) {
  const reversedPath = [];
  for (var filefolder in directories) {
    if (filefolder.id === id) {
      reversedPath.push(filefolder.name);
      return reversedPath;
    } else if (filefolder.data?.items.length > 0){
      const path = findPathById(id, filefolder.data.items);
      if (path.length > 0) {
        reversedPath.push(filefolder.name);
        return reversedPath.concat(path);
      }
    }
  }
}

export { findDirectoryById, findPathById, Directories }