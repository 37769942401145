import React from 'react';
import Window from '../Window/Window';
import SnakeGame from '../Games/Snake/SnakeGame';

const Game = (props) => {
  return(
    <Window title={props.title} id={props.windowId} width={400} height={500}>
      <SnakeGame />
    </Window>
  )
}

export default Game;