import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { ApplicationContext } from '../../contexts/ApplicationContext'
import Emitter from '../../utils/Emitter'
import events from '../../constants/events'
import Dialog from '../AppViews/Dialog'
import { Applications } from '../../constants/applications'
import { AboutDialog, AboutThisMaxDialog } from '../Dialogs'
import Terminal from '../Terminal/Terminal'
import AnimatedCharacters from '../Terminal/AnimatedCharacters'
import { upload } from '@testing-library/user-event/dist/upload'
import { IconRenderer, Icons } from '../../assets/Icons'

const MLOGO = () => (<Icons.MLogo width={160} height={120} strokeWidth={2} strokeColor={'#222222'} color1={'#5dd9df'} color2={'#c084e1'} />)

const BootScreenMetaData = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;

    p {
        color: ${props => props.theme.colors.terminalTextColor};
        font-size: ${props => props.theme.fontSizes.medium};
    }
`

const FullScreenOverlay = styled.div`
    position: fixed;
    background: ${props => props.theme.backgroundColors.overlay};
    top: 0;
    bottom: ${props => props.$overlayActive ? '0' : '100vh'};
    left: 0;
    right: 0;
    z-index: 9;
    transition: bottom ${props => props.$animationTime}ms;
`

const StyledShutDownMessage = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.shutdownTextColor};
    font-weight: 800;
    font-size: 2rem;
`

const overlayDelay = 500;
const overlayAnimationTime = 1000;
const bootScreenTime = 7000;

const ShutDownMessage = () => {
    return (
        <StyledShutDownMessage>
            It is now safe to turn off your computer
        </StyledShutDownMessage>
    );
}

const OverlayManager = (props) => {
    const [overlayOn, setOverlayOn] = useState(true);
    const [renderTerminal, setRenderTerminal] = useState(false);
    const [renderShutdown, setRenderShutdown] = useState(false);
    const [renderBootScreen, setRenderBootScreen] = useState(false);

    const { removeWindow } = React.useContext(ApplicationContext)
    const Shutdown = () => {
        CloseDialog();
        setOverlayOn(true);
        setTimeout(() => {
            setRenderTerminal(true);
            setTimeout(() => {
                props.onShutdown();
                setRenderShutdown(true);
                setRenderTerminal(false);
            }, 5000);
        }, overlayAnimationTime);
    }
    const CloseDialog = () => { removeWindow(Object.values(Applications).indexOf(Applications.DIALOG)) }

    useEffect(() => {
        //let bootScreenTimeout = setTimeout();

        if (props.fromShutdown) {
            setRenderBootScreen(true);
            setTimeout(() => {
                setRenderBootScreen(false);
                setTimeout(() => {
                    setOverlayOn(false);
                    props.onBoot();
                }, overlayDelay)
            }, bootScreenTime)
        } else {
            setTimeout(() => {
                setOverlayOn(false);
            }, overlayDelay)
        }

        Emitter.on(events.SHUT_DOWN, () => {
            Emitter.emit(events.OPEN_WINDOW, {
                type: Applications.DIALOG,
                //id: 39398,
                data: {
                    text: 'Are you sure you want to shutdown this machine?',
                    primaryAction: Shutdown,
                    secondaryAction: CloseDialog,
                }
            });
        });

        Emitter.on(events.ABOUT, () => {
            Emitter.emit(events.OPEN_WINDOW, {
                type: Applications.DIALOG,
                data: AboutDialog
            });
        });

        Emitter.on(events.ABOUT_THIS_MAX, () => {
            Emitter.emit(events.OPEN_WINDOW, {
                type: Applications.DIALOG,
                data: AboutThisMaxDialog
            });
        });

        Emitter.on(events.SHUT_DOWN_CONFIRM, () => {
            setOverlayOn(true);
        })

        return (() => {
            // TO DO: cleanup emitters
            Emitter.off(events.SHUT_DOWN);
            Emitter.off(events.ABOUT);
            Emitter.off(events.SHUT_DOWN_CONFIRM);
        })
    },[]);

    return (
        <div>
            <FullScreenOverlay $overlayActive={overlayOn} $animationTime={overlayAnimationTime}>
                {renderTerminal && <Terminal
                    initialText={[
                        {delay: 0, text: "Logging out for user Max K Howard..."},
                        {text: "Generating Tautologies..."},
                        {text: "Unauthorized use is prohibited."},
                        {text: "DoorsOS is a product of the DoorsOS corporation."},
                        {text: ""},
                        {text: ""},
                        {text: "Shutting down..."},
                        {frames:['|', '/', '-', '\\'], delay:0, animationSpeed:150 },
                    ]}
                    animationTimePerCharacter={20}
                />}
                {renderShutdown &&
                    <ShutDownMessage />
                }
                {renderBootScreen &&
                    <>
                        <Terminal
                            initialText={[
                                {delay: 0, text: "Technicolour Processing Bios Version 1.0, 1988"},
                                {delay: 0, text: "Copyright (C) MaxK Software, Inc."},
                                {text: " ", emptyLine:true},
                                {text: "233 MHz Ventious II", bold:true},
                                {text: "Checking System Health"},
                                {text: "Memory Test, 32 MB OK"},
                                {text: "  Graphics Test, ThrusterX-960 OK"},
                                {text: "  Sound Test, ShockMaster 2.0 OK"},
                                {text: "  Hard Drive Test, 4GB OK"},
                                {text: "  Optical Drive Test, 24x CD-ROM drive OK"},
                                {text: "  Floppy Drive Test, 3 1/2\" Disk Drive Module OK"},
                                {text: " "},
                                {delay: 0, text: "Booting kernel"},
                                {delay: 500, text: "Loading MaxK OS ..."},
                                {text: "..."},
                                {text: "MaxKOS is a product of the MaxKOS corporation."},
                                {text: ""},
                                {text: ""},
                                {text: "Starting up ..."},
                                {frames:['|', '/', '-', '\\'], delay:0, animationSpeed:150 },]}
                            animationTimePerCharacter={10}
                        />
                        <BootScreenMetaData>
                            <IconRenderer Component={MLOGO} />
                            <p>Technicolor Processing</p>
                        </BootScreenMetaData>
                    </>
                }
            </FullScreenOverlay>
        </div>
    )
}

export default OverlayManager;