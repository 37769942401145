import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { ThemeContext } from '../../contexts/ThemeContext';
import { Themes } from '../../Theme/Theme';
import Window from '../Window/Window';
import Slider from '../Input/Slider';
import { IconRenderer, Icons } from '../../assets/Icons';

const VampContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  container-type: size;
`

const VideoBackground = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 2;
  flex-shrink: 9;
  container-type: size;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.backgroundColors.vamp};
`;

const PlayerContainer = styled.div`
  position: relative;
  aspect-ratio: ${props => props.$aspectRatio};
  width: 100%;

  @container (min-aspect-ratio: ${props => props.$aspectRatio}) {
    width: auto;
    height: 100%;
  }
`

const StyledVideo = styled.video`
  width: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoController = styled.div`
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 40px;
  background: linear-gradient(
    180deg,
    ${props => props.theme.backgroundColors.vampControlsGradient1},
    ${props => props.theme.backgroundColors.vampControlsGradient2}
  );

  @container (max-width: 500px) {
    flex-direction: column-reverse;
    height: 100px;
  }
`;

const ButtonsAndData = styled.div`
  width: 66.66%;
  display: flex;
  position: relative;

  @container (max-width: 500px) {
    width: 100%;
  }
`;


const ButtonsContainer = styled.div`
  //min-width: 150px;
  width: 66%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @container (max-width: 500px) {
    width: 100%;
  }
`

const VideoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size ? props.size : '34px'};
  height: ${props => props.size ? props.size : '34px'};
  border-radius: 100%;
  background: linear-gradient(
    0deg,
    ${props => props.theme.backgroundColors.vampControlsGradient1},
    ${props => props.theme.backgroundColors.vampControlsGradient2}
  );
  border: ${props => props.theme.border.vampInput};

  &:active {
    border-color: ${props => props.theme.colors.borderColor1};
  }
`

const VolumePanel = styled.div`
  display: flex;
  //min-width: 150px;
  width: 33.33%;
  padding-right: 40px;
  box-sizing: border-box;

  @container (max-width: 500px) {
    width: 100%;
  }
`

const DataPanel = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @container (max-width: 500px) {
    width: auto;
    position: absolute;
    right: -15px;
    top: 10px;
  }
`

const DataDisplay = styled.div`
  width: 60px;
  height: 28px;
  background: #333;
  color: #BCEAC1;
  font-size: 16px;
  box-shadow: 1px 1px 1px #fff;
  border-radius: 4px;
  font-weight: 400;

  &>div {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: -1px -1px 1px rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SeekSlider = styled.div`
  height: ${props => props.$hidden ? 0 : '10px'};
  width: 100%;
  transition: height 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #656565;

  &:after{
    content: '';
    display: block;
    width: ${props => props.$played * 100}%;
    height: 100%;
    background: #FFC670;
  }
`

const Vamp = (props) => {
  const playerRef = useRef(null);
  const [url, setUrl] = useState(null);
  const [pip, setPip] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [controls, setControls] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [loop, setLoop] = useState(false);
  const [showSeekSlider, setShowSeekSlider] = useState(false);
  
  const {theme, setTheme} = useContext(ThemeContext);

  const aspectRatio = props.aspectRatio || '16/9';
  const handleSeekMouseDown = e => {}
  const handleSeekChange = e => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const xPercent = x / rect.width;
    setPlayed(xPercent);
    playerRef.current.seekTo(xPercent);
  }
  const handleSeekMouseUp = e => {}
  const handlePlayPause = e => {
    setPlaying(!playing);
  }
  const handleProgress = state => {
    setPlayed(state.played);
  }
  const handleFastForward = e => {
    switch(playbackRate) {
      case 1:
        setPlaybackRate(2);
        break;
      case 2:
        setPlaybackRate(4);
        break;
      case 4:
        setPlaybackRate(8);
        break;
      case 8:
        setPlaybackRate(1);
        break;
      default:
        setPlaybackRate(1);
        break;
    }
  }

  const handleSlow = e => {
    switch(playbackRate) {
      case 1:
        setPlaybackRate(0.75);
        break;
      case 0.75:
        setPlaybackRate(0.5);
        break;
      case 0.5:
        setPlaybackRate(0.25);
        break;
      default:
        setPlaybackRate(1);
        break;
    }
  }

  const handleVolumeChange = e => { setVolume(parseFloat(e.target.value)) }

  return(
    <Window
      title={props.fileName}
      id={props.windowId}
      width={600}
      height={400}
      Icon={Icons.SmallVideoIcon}
      minWidth={340}
      minHeight={135}
      >
      <VampContainer>
        <VideoBackground
          onMouseEnter={() => {setShowSeekSlider(true)}}
          onMouseLeave={() => {setShowSeekSlider(false)}}
        >
          <PlayerContainer $aspectRatio={aspectRatio}>
            <ReactPlayer
              ref={playerRef}
              className='react-player'
              width='100%'
              height='100%'
              //pip={pip}
              playing={playing}
              controls={controls}
              loop={loop}
              playbackRate={playbackRate}
              volume={volume}
              muted={muted}
              onReady={() => console.log('onReady')}
              onStart={() => console.log('onStart')}
              // onPlay={this.handlePlay}
              // onEnablePIP={this.handleEnablePIP}
              // onDisablePIP={this.handleDisablePIP}
              // onPause={this.handlePause}
              onBuffer={() => console.log('onBuffer')}
              // onPlaybackRateChange={this.handleOnPlaybackRateChange}
              // onSeek={e => console.log('onSeek', e)}
              // onEnded={this.handleEnded}
              // onError={e => console.log('onError', e)}
              onProgress={handleProgress}
              // onDuration={this.handleDuration}
              // onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}
              url={props.src}
          />
          </PlayerContainer>
          <SeekSlider $hidden={!showSeekSlider} $played={played} onClick={handleSeekChange} />
        </VideoBackground>

        <VideoController>
          <VolumePanel>
            <Slider
              type='range'
              min={0}
              max={1}
              step='any'
              value={volume}
              onChange={handleVolumeChange}
            />
          </VolumePanel>
          <ButtonsAndData>
            <ButtonsContainer>
              <VideoButton onClick={handleSlow}>
                <IconRenderer Component={Icons.Slow} color={Themes[theme].colors.vampIconColor} />
              </VideoButton>
              <VideoButton size={'50px'} onClick={handlePlayPause}>
                { playing ?
                  <Icons.Pause color={Themes[theme].colors.vampIconColor} /> :
                  <Icons.Play color={Themes[theme].colors.vampIconColor} />
                }
              </VideoButton>
              <VideoButton onClick={handleFastForward}>
                <IconRenderer Component={Icons.FastForward} color={Themes[theme].colors.vampIconColor} />
              </VideoButton>
            </ButtonsContainer>
            <DataPanel>
              <DataDisplay>
                <div>
                  {playbackRate}x
                </div>
              </DataDisplay>
            </DataPanel>
          </ButtonsAndData>
        </VideoController>
      </VampContainer>
    </Window>
  )
}

export default Vamp;