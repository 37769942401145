import React from "react";
import styled from "styled-components";
//import { FileTypes } from '../../Data/MockCmsData';
import Emitter from '../../utils/Emitter';
import events from '../../constants/events';
import { Applications, FileTypes, FileTypeImages, FileTypeApplications } from '../../constants/applications';
import { IconRenderer, Icons } from '../../assets/Icons';

//theme vars
const ItemHeight = '98px'
const ItemWidth = '110px'
const IconHeight = '68px'
const IconWidth = '68px'
const Gap = '10px'

const FileGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.$horizontal ? 'row' : 'column'};
  align-content: flex-start;
  justify-content: flex-start;
  gap: ${Gap};
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  width: fit-content;
  height: 100%;
`

const FileGridItem = styled.button`
  background: transparent;
  border-radius: 8px;
  outline: none;
  border: 0;
  height: auto;
  width: ${ItemWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 2px;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 200;
  margin-right: 8px;
  
  &:focus {
    background: ${props => props.theme.colors.fileFocusColor};
  }

  &:active {
    background: ${props => props.theme.colors.fileActiveColor};
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const IconContainer = styled.div`
  width: 72px;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const FileName = styled.div`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 300;
  text-wrap: nowrap;
  color: ${props => props.theme.colors.textColor};
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden
`

const FileGrid = ({files, horizontal=false}) => {
  const OnClickFile = (file) => {
    Emitter.emit(events.OPEN_WINDOW, {
      id: file.id,
      type: file.type,
      title: file.name,
      data: file.data || {},
    });
  }

  return (
    <FileGridContainer $horizontal={horizontal}>
      { files.map((file)=>{
        const hasImg = !!file?.iconUrl
        const Icon = hasImg ? Icons[file.iconUrl] : FileTypeImages[file.type]
        return (
          <FileGridItem key={`FileGridItem${file.id}`} onDoubleClick={() => {
            //if state says a doors window is already open, check if it is active, then open the directory in that folder, else open a new window.
            OnClickFile(file);
          }}>
            {/* <img src={hasImg ? file.iconUrl : FileTypeImages[file.type] } alt="" /> */}
            <IconContainer>
              <IconRenderer Component={Icon} />
            </IconContainer>
            <FileName>
              { file.name }
            </FileName>
          </FileGridItem>
        )
      })}
    </FileGridContainer>
  )
}

export default FileGrid