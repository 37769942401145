import React, { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { Applications } from '../../constants/applications';
import Window from '../Window/Window';
import FileGrid from '../Window/FileGrid';
import Navigator from '../Window/Navigator';
import { findDirectoryById, findPathById, Directories } from '../../utils/Directories';
import Emitter from '../../utils/Emitter';
import events from '../../constants/events';
import { FileTypes } from '../../constants/applications';

const Files = ({windowId, directoryId, directoryData}) => {
  //const [currentPath, setCurrentPath] = useState(findPathById(directoryData.id))
  const fileObject = findDirectoryById(directoryId)

  const onClickBack = () => {
    if (fileObject.parent === -1) return
    const parentDirectory = findDirectoryById(fileObject.parent)
    Emitter.emit(events.OPEN_WINDOW, {
      id: parentDirectory.id,
      type: FileTypes.FOLDER,
      title: parentDirectory.name,
      data: parentDirectory.data,
    });
  }

  return (
    <Window title={fileObject.name} id={windowId}>
      <Navigator onClickBack={onClickBack} path={fileObject.path} />
      <FileGrid
        files={fileObject.data?.items ? fileObject.data.items : []}
        horizontal
      />
    </Window>
  )
}

export default Files