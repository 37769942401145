import React from "react";
import styled from "styled-components";

const SIZES = {
  SMALL: '24px',
  MEDIUM: '36px',
  LARGE: '48px',
}

const StyledButton = styled.button`
  height: ${props => props.size || SIZES.MEDIUM};
  width: auto;
  min-width: 80px;
  border-radius: 16px;
  z-index: 2;
  border: 0px solid ${props => props.theme.border.colors.secondary};
  transition: border-width 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #555;
  background: #fafafa;
  font-weight: bold;
  color: ${props => props.theme.buttons.buttonTextColor};
  padding: 4px 40px;

  &:hover {
    background: #ddd;
  }

  &:active {
    background: #999;
  }
`;

const Button = ({
  onClick,
  role,
  children,
  backgroundColor
}) => {
  return(
    <StyledButton
      className='generic-button'
      backgroundColor={backgroundColor}
      role={role}
      onClick={onClick}
    >
      { children }
    </StyledButton>
  )
}

export default Button;

Button.defaultProps = {
  onClick: () => {},
  role: '',
  title: '',
  backgroundColor: '#fff',
}