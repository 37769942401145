import React, { useState } from "react";
import styled from "styled-components";
import Emitter from "../../utils/Emitter";
import events from "../../constants/events";
import { Applications } from "../../constants/applications";

const ItemHeight = 60;

const CollapsibleWrapper = styled.div`
  display: flex;
`

const Collapsible = styled.div`
  height: auto;
  width: 200px;
  border: 2px solid ${props => props.theme.borderColor1};
  background: ${props => props.theme.backgroundColors.window};
  z-index: 1;
  transform: scaleY(${props => props.$collapsed ? 0 : 1});
  transform-origin: ${props => props.$anchor || `bottom`};
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  color: ${props => props.theme.colors.textColor};
`
const StyledCollapsibleItem = styled.div`
  height: auto;
  box-sizing: border-box;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${props => props.theme.colors.borderColor1};
  overflow: visible;

  &:last-child{
    border-bottom: none;
  }

  &:hover {
    background: ${props => props.theme.colors.highlightColor};
  }
`

const itemsToCollapsibleState = (items) => {
  return items.map((item) => {

  })
}

const exampleState = {
  title: 'sjfjs',
  collapsed: true,
  items: [
    {
      title: 'soup',
    },
    {
      title: 'bread',
      collapsed: true,
      items: [
        { title: 'hello' }
      ]
    }
  ]
}

const CollapsibleContainer = styled.div`
  position: absolute;
  left: 200px;
`

const StyledIcon = styled.i`
  margin-right: 12px;
  width: 20px;
  height: 20px;
`

const CollapsibleItem = ({title, appLink, icon, items, anchor, event, depth, maxDepth}) => {
  const [collapsed, setCollapsed] = useState(true)
  
  const onClickHandler = () => {
    if (!!appLink) {
      Emitter.emit(events.OPEN_WINDOW, {
        id: 0,
        type: appLink,
        title: title,
        //data: {},
      });
    }
    if (!!event) {
      Emitter.emit(event);
    }
  }
  
  const onTouchItem = (e) => {
    e.preventDefault();
    //e.stopPropagation();
    setCollapsed(false);

    if (!!appLink) {
      Emitter.emit(events.OPEN_WINDOW, {
        id: 0,
        type: appLink,
        title: title,
        //data: {},
      });
    }
    if (!!event) {
      Emitter.emit(event);
    }
  }

  return (
    <StyledCollapsibleItem onMouseEnter={() => {setCollapsed(false)}} onMouseLeave={()=>{setCollapsed(true)}} onClick={onClickHandler} onTouchEnd={(e) => onTouchItem(e)}>
      <span><StyledIcon className={icon} />{title}</span>
        { items?.length > 0 && <span>{`>`}</span> }
        { items?.length > 0 &&
          <CollapsibleContainer>
            <CollapsiblePane collapsed={collapsed} items={items} anchor={anchor} />
          </CollapsibleContainer>
        }
    </StyledCollapsibleItem>
  )
}

const CollapsiblePane = ({collapsed, items, anchor, onClickItem }) => {
  const [collapsibleState, setCollapsibleState] = useState({});
  const depth = 0;

  return (
    <CollapsibleWrapper>
      <Collapsible $collapsed={collapsed} $anchor={anchor} onClick={onClickItem}>
        { items.map((item, i)=>(
          <CollapsibleItem key={item.title+i} appLink={item.appLink} title={item.title} icon={item.icon} items={item.items} anchor={item.anchor} event={item.event} />
        ))}
      </Collapsible>
    </CollapsibleWrapper>
  )
}

export default CollapsiblePane;