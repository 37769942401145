import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../contexts/ThemeContext';
import { Themes } from '../../Theme/Theme';

const ButtonDepth = 5;
const ButtonRecessDepth = 2;
const ButtonBorderSize = 2;
const ButtonSize = 44;
const TransitionTime = 0.2;
const ButtonBorderColor = '#555555';
const ButtonBackgroundColor = '#EEE2E2';
const ButtonSideColor = '#D4C6C6';

// const ButtonDepthPx = '8px';
// const ButtonBorderSizePx = '2px';
// const ButtonSizePx = '42px';
// const ButtonExtendedSize = '';

const ButtonBackgroundClipPath = (originX, originY, width, height, depth) => {
  return (`
    ${originX}px ${originY}px,
    ${originX + width}px ${originY}px,
    ${originX + width + depth}px ${originY + depth}px,
    ${originX + width + depth}px ${originY + depth + height}px,
    ${originX + depth}px ${originY + depth + height}px,
    ${originX}px ${originY + height}px
  `);
}

const ButtonContainer = styled.div`
  display: block;
  position: relative;
  margin: -${ButtonBorderSize}px -${ButtonBorderSize}px -${ButtonBorderSize}px 0;
  z-index: ${props => (100 - props.index)};
  &::before {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    content: ' ';
    display: block;
    background: ${ButtonBorderColor};
    position: absolute;
    clip-path: polygon(${ props => ButtonBackgroundClipPath(0, 0, props.width, props.height, 0)});
    transition: clip-path ${TransitionTime}s, width ${TransitionTime}s, height ${TransitionTime}s;
    z-index: 1;
  }
  &::after {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    content: ' ';
    display: block;
    background: ${props => props.$sideColor};
    position: absolute;
    transition: clip-path ${TransitionTime}s, width ${TransitionTime}s, height ${TransitionTime}s;
    clip-path: polygon(${props => ButtonBackgroundClipPath(2, 2, props.width-2*ButtonBorderSize, props.height-2*ButtonBorderSize, 0)});
    top: 0;
    left: 0;
    z-index: 1;
  }

  ${props => !props.$isDisabled && (`
    &:hover::before {
      clip-path: polygon(${ButtonBackgroundClipPath(0, 0, props.width, props.height, ButtonDepth)});
      width: ${props.width + ButtonDepth}px;
      height: ${props.height + ButtonDepth}px;
      z-index: 3;
    }
    &:hover::after {
      clip-path: polygon(${ButtonBackgroundClipPath(2, 2, props.width-ButtonBorderSize-1, props.height-ButtonBorderSize-1, ButtonDepth)});
      z-index: 3;
      width: ${props.width + ButtonDepth - ButtonBorderSize}px;
      height: ${props.height + ButtonDepth - ButtonBorderSize}px;
    }
  `)}

    &:active {
      &::before {
        width: ${props => props.width}px;
        height: ${props => props.height}px;
      }
      &::after {
        width: ${props => props.width - ButtonBorderSize}px;
        height: ${props => props.height - ButtonBorderSize}px;
      }
    }
`

const StyledButton = styled.button`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  outline: none;
  border: none;
  border: ${ButtonBorderSize}px solid ${ props => props.theme.colors.borderColor2};
  box-sizing: border-box;
  border-radius: 0;
  transition: transform ${TransitionTime}s;
  position: relative;
  z-index: 2;
  background-color: ${props => props.color};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.textColor};
  
  &:hover:enabled {
    z-index: 4;
    transform: translate3d(${ButtonDepth}px, ${ButtonDepth}px, 0);

    &::before {
      position: absolute;
      left: -${ButtonDepth + 2}px;
      top: -${ButtonDepth + 2}px;
      height: ${props => props.height + ButtonDepth + 2}px;
      width: ${props => props.width + ButtonDepth + 2}px;
      background: transparent;
      z-index: 1;
      content: ' ';
    }
  }
  &:active:enabled {
    transform: translate3d(-${ButtonRecessDepth}px, -${ButtonRecessDepth}px, 0);
    background-color: ${props => props.$sideColor};

    &::before {
      left: 0;
      top: 0;
    }
  }
  &:disabled {
    opacity: 0.5;
  }
`

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export default function DeepButton({index, children, onClick, width, height, color, disabled=false}) {
  const {theme, setTheme} = useContext(ThemeContext);
  
  const Width = width || ButtonSize;
  const Height = height || ButtonSize;
  const Color = color || Themes[theme].backgroundColors.button;
  const RGBsideColor = hexToRgb(Color);
  const SideColor = `rgba(${RGBsideColor.r}, ${RGBsideColor.g}, ${RGBsideColor.b}, 0.8)`;
  
  return (
    <ButtonContainer
      index={index}
      width={Width}
      height={Height}
      className={'button-container'}
      $sideColor={SideColor}
      $isDisabled={disabled}  
    >
      <StyledButton
        index={index}
        onClick={() => onClick()}
        width={Width}
        height={Height}
        color={Color}
        $sideColor={SideColor}
        disabled={disabled}
      >
        { children }
      </StyledButton>
    </ButtonContainer>
  )
}