import React, {useState, useRef, useContext} from 'react';
import styled from 'styled-components';
import CollapsiblePane from '../Panes/CollapsibleList';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { GoMenuItems } from '../../Data/MockCmsData';
import { IconRenderer, Icons } from '../../assets/Icons';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { ApplicationGoBarIcons, Applications } from '../../constants/applications';
import Clock from './Clock';
import { ThemeContext } from '../../contexts/ThemeContext';
import { Themes } from '../../Theme/Theme';
import events from '../../constants/events';
import Emitter from '../../utils/Emitter';
import { NetworkDialog } from '../Dialogs';

const GoBarContainer = styled.div`
  // vars
  background: ${props => props.theme.backgroundColors.goBar};
  border: 2px solid ${props => props.theme.colors.borderColor1};
  border-bottom: 0;
  //border-top-left-radius: ${props => props.theme.border.radius.primary};
  //border-top-right-radius: ${props => props.theme.border.radius.primary};
  height: ${props => props.theme.goBar.height};
  padding: 2px;
  box-sizing: border-box;
  width: 100vw;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2;
`

const CollapsibleMenuContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 20px;
  pointer-events: ${props => props.$collapsed ? 'none' : 'default'};
  //overflow: hidden;
`

const GoButtonContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GoMenuButton = styled.button`
  background: none;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    & > svg{
      //fill: black !important;
      filter: brightness(85%);
    }
  }
  &:active {
    & > svg{
      //fill: black !important;
      filter: brightness(50%);
    }
  }
`

const Spacer = styled.div`
  width: 2px;
  height: 30px;
  background: ${props => props.theme.colors.borderColor1};
  opacity: 0.4;
`

const AppsButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 24px;
  flex-grow: 2;
`

const OpenAppButton = styled.button`
  border: 0;
  padding: 4px;
  background: none;
  &:hover {
    & > svg{
      //fill: black !important;
      filter: brightness(85%);
    }
  }
  &:active {
    & > svg{
      //fill: black !important;
      filter: brightness(50%);
    }
  }
`

const GoBarToolsContainer = styled.div`
  justify-self: flex-end;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${props => props.theme.colors.textColor};
`

const GlobeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const GoBar = () => {
  const [goMenuCollapsed, setGoMenuCollapsed] = useState(true);
  const { windows, setActiveWindow } = useContext(ApplicationContext);
  const { theme } = useContext(ThemeContext);
  
  const onClickGo = () => {
    setGoMenuCollapsed(!goMenuCollapsed)
  }

  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, () => {
    setGoMenuCollapsed(true)
  });

  return (
    <GoBarContainer ref={wrapperRef}>
      <GoButtonContainer>
        <GoMenuButton className="another-class" onClick={ () => onClickGo() } width={80} height={44}>
          <IconRenderer Component={Icons.MLogo} leftColor={Themes[theme].colors.logo1} rightColor={Themes[theme].colors.logo2} strokeColor={Themes[theme].colors.borderColor1} />
        </GoMenuButton>
      </GoButtonContainer>
      <Spacer />
      <AppsButtonsContainer>
        { windows.map((window)=>{
          const OpenAppIcon = ApplicationGoBarIcons[window.application];
          if (OpenAppIcon) return (
            <OpenAppButton onClick={() => setActiveWindow(window.id)} key={`goBarWindow${window.id}`}>
              <OpenAppIcon height={24} width={34} />
            </OpenAppButton>
          )
          return null
        })}
      </AppsButtonsContainer>
      <Spacer />
      <GoBarToolsContainer>
        <GlobeContainer onClick={() => { 
          Emitter.emit(events.OPEN_WINDOW, {
            type: Applications.DIALOG,
            data: NetworkDialog
        });
        }}>
          <IconRenderer Component={Icons.Globe} offset={50} size={20} />
        </GlobeContainer>
        <i className='fa-solid fa-volume-high'></i>
        <Clock />
      </GoBarToolsContainer>
      <CollapsibleMenuContainer $collapsed={goMenuCollapsed}>
        <CollapsiblePane collapsed={goMenuCollapsed} items={GoMenuItems} onClickItem={() => setGoMenuCollapsed(true)} />
      </CollapsibleMenuContainer>
    </GoBarContainer>
  )
}

export default GoBar