import React from 'react';
import styled, { css } from 'styled-components';

const ThumbMixins = css`
  -webkit-appearance: none;
  border: ${props => props.theme.border.vampInput};
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background: linear-gradient(
    180deg,
    ${props => props.theme.backgroundColors.vampControlsGradient1},
    ${props => props.theme.backgroundColors.vampControlsGradient2}
  );  cursor: pointer;
  margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
`

const TrackMixin = css`
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: linear-gradient(
    0deg,
    ${props => props.theme.backgroundColors.vampControlsGradient1},
    ${props => props.theme.backgroundColors.vampControlsGradient2}
  );
  border-radius: 8px;
  border: ${props => props.theme.border.vampInput};
`

const StyledInputSlider = styled.input`
   -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  //width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  width: 100%;

  &::-moz-range-thumb {
    ${ThumbMixins}
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${ThumbMixins}
  }

  &::-webkit-slider-runnable-track {
    ${TrackMixin}
  }

  &::-moz-range-track {
    ${TrackMixin}
  }

  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
`

const Slider = (props) => (
  <StyledInputSlider {...props} />
)

export default Slider