import React from 'react';
import styled from 'styled-components';
import Button from '../Buttons/Button';

const StyledSpeechBubble = styled.div`
  position: absolute;
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 0.4em;
  padding: 0.5em;
  margin: 0.5em;
  font-size: 0.8em;
  box-shadow: 0 0 0.5em rgba(0,0,0,0.2);
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -0.5em;
    border-width: 0.5em;
    border-style: solid;
    border-color: transparent #f1f1f1 transparent transparent;
  }
`;

export const SpeechBubble = ({ position, children, text, primaryCtaLabel, primaryCtaOnClick }) => {
  let speechBubblePosition = ""
  
  if (["top", "bottom", "left", "right"].includes(position)) {
    speechBubblePosition = position
  }

  return (
    <StyledSpeechBubble
      className="speech-bubble"
      position={speechBubblePosition}
    >
      { text && 
        <p>{text}</p>
      }
      { primaryCtaLabel &&
        <Button onClick={primaryCtaOnClick}>{ primaryCtaLabel }</Button>
      }
    </StyledSpeechBubble>
  )
}