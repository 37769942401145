import './App.css';
import React, { useContext } from 'react';
import GoBar from './components/GoBar/GoBar';
import styled from 'styled-components';
import FileGrid from './components/Window/FileGrid';
import WindowManager from './components/Managers/WindowManager';
import OverlayManager from './components/Managers/OverlayManager';
import { Icons } from './assets/Icons'
import { ThemeContext } from './contexts/ThemeContext';
import { AppStateContext } from './contexts/AppStateContext';
import { Themes } from './Theme/Theme';

import { NewDirectories } from './Data/MockCmsData';

const AppContainer = styled.div`
  position: relative;
  //background: ${props => props.theme.backgroundGradient};
  //background-image: url(BackgroundString);
  color: ${props => props.theme.colors.textColor};
  
  & a {
    color: ${props => props.theme.colors.linkColor};

    &:visited {
      color: ${props => props.theme.colors.linkColor};
    }
  }
`

const BackgroundImage = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 0;

  & > svg {
    width: 100vw;
    height: 100vh;
  }
`

const Desktop = styled.div`
  position: absolute;
  top: 0;
  bottom: 40px;
  left: 0;
  right: 0;
`

const DesktopFolders = NewDirectories.find(element => element.name === 'Desktop')

function App() {
  const {theme, setTheme} = useContext(ThemeContext)
  const {appState, setAppStateValue} = useContext(AppStateContext)
  const shouldShowBootScreen = (appState?.reboot === undefined) ? true : !!(appState.reboot);

  return (
    <AppContainer className="App">
      <BackgroundImage>
        <Icons.BackgroundHills
          bgColor1 = {Themes[theme].desktopBackgrounds.bgColor1}
          bgColor2 = {Themes[theme].desktopBackgrounds.bgColor2}
          hill1Color = {Themes[theme].desktopBackgrounds.hill1Color}
          hill2Color = {Themes[theme].desktopBackgrounds.hill2Color}
          hill3Color = {Themes[theme].desktopBackgrounds.hill3Color}
          hill4Color = {Themes[theme].desktopBackgrounds.hill4Color}
          fillOpacity = {Themes[theme].desktopBackgrounds.fillOpacity}
        />
      </BackgroundImage>
      <WindowManager />
      <Desktop>
        <FileGrid
          files={DesktopFolders.data.items}
        />
      </Desktop>
      <GoBar />
      <OverlayManager
        fromShutdown={shouldShowBootScreen}
        onBoot={() => { setAppStateValue('reboot', false) }}
        onShutdown={() => { setAppStateValue('reboot', true) }}
      />
    </AppContainer>
  );
}

export default App;
