import React, { useState, useEffect } from 'react';

const AnimatedCharacters = ({ frames, delay, animationSpeed }) => {
  const [currentFrameIndex, setCurrentFrameIndex] = useState(-1);

  useEffect(() => {
    let timeoutId;
    let intervalId;

    const animateFrames = () => {
      intervalId = setInterval(() => {
        setCurrentFrameIndex((prevIndex) => (prevIndex + 1) % frames.length);
      }, animationSpeed);
    };

    // Start animation after delay
    timeoutId = setTimeout(() => {
      animateFrames();
    }, delay);

    // Clean up timeout when component unmounts
    return () => clearTimeout(timeoutId);
  }, [frames, delay, animationSpeed]);
  return (<>
    {currentFrameIndex > -1 ? (<div>{frames[currentFrameIndex]}</div>) : null}
    </>);
};

export default AnimatedCharacters;
