import { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';

export const Animations = {
  Still: {
    rowNumber: 0,
    frameCount: 0,
    duration: 5000,
  },
  Blink: {
    rowNumber: 0,
    frameCount: 5,
  },
  Wave: {
    rowNumber: 1,
    frameCount: 18,
  },
  Appear: {
    rowNumber: 2,
    frameCount: 10,
  },
  WaveLetter: {
    rowNumber: 3,
    frameCount: 15,
  },
  Disappear: {
    rowNumber: 4,
    frameCount: 15,
  }
}

export const AnimationStates = {
  animateIn: {
    animations: [Animations.Appear],
    loop: false,
  },
  passive: {
    animations: [Animations.Still, Animations.Blink],
    loop: true,
  },

}

const useClipsterStateMachine = () => {

}
/*
StateType = {
  State: {
    AnimationState: AnimationsStates.example,
    duration: number, //(0 for inf),
    speechBubble: { //one of or null/undefined
      text: text,
      ctalabel: text,
      ctaStateTransition: 'StateName',
    },
    nextState: 'StateName',
    onEnter: () => {},
    onExit: () => {},
  },
}
*/
const ClipsterStateMachine = {
  Open: {
    AnimationState: AnimationStates.animateIn,
    duration: 1,
    nextState: 'Pause'
  },
  Pause: {
    AnimationState: AnimationStates.passive,
    duration: 2,
    nextState: 'AskAboutLetter',
  },
  AskAboutLetter: {
    AnimationState: AnimationStates.passive,
    duration: 0, //(0 for inf),
    speechBubble: { //one of or null/undefined
      text: 'Hey. I like letters. Do you like letters? Wanna turn this boring old thing into a letter?',
      primaryCtaLabel: 'Okay',
      primaryCtaState: 'InfinitePassive',
      secondaryCtaLabel: 'No thanks',
      ctaStateTransition: 'InfinitePassive',
    },
    nextState: 'StateName',
    onEnter: () => {},
    onExit: () => {},
  },
  InfinitePassive: {
    AnimationState: AnimationStates.passive,
    duration: 0,
  },
}

