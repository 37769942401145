import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const addLeadingZeros = (timeString) => {
    return (
        timeString.length > 1 ?
            timeString :
            `0${timeString}`
    )
}

const StyledClock = styled.div`
    width: 46px;
`

const Clock = () => {
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    //const [seconds, setSeconds] = useState('00');

    useEffect(() => {
        setTime();
        const remainingSeconds = 60 - (new Date().getSeconds())

        const minuteTimeout = setTimeout(
            setTime,
            remainingSeconds*1000
        );
    }, [minutes]);

    const setTime = () => {
        const date = new Date();
        
        setHours(addLeadingZeros(`${date.getHours()}`));
        setMinutes(addLeadingZeros(`${date.getMinutes()}`));
    }

    return (
        <StyledClock>{ hours + ':' + minutes }</StyledClock>
    )
}

export default Clock