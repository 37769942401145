import React, { useRef, useEffect } from 'react'

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // do something on click outside
        handler()
      }
    }
    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      // unbind
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [ref])
}

export default useOnClickOutside

// export default function useOnClickOutside(props) {
//   const wrapperRef = useRef(null);
//   useOutsideAlerter(wrapperRef);
//   return <div ref={wrapperRef}>{ props.children }</div>
// }