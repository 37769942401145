import React, { useContext } from "react";
import rgb2hex from 'rgb2hex';
import styled from "styled-components";
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { Icons } from "../../assets/Icons";
import Theme, { Themes } from '../../Theme/Theme';
import { ThemeContext } from '../../contexts/ThemeContext';

const TitleBarButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  gap: 4px;
`

const TitleButton = styled.button`
  height: 24px;
  width: 24px;
  padding: 4px;
  border: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 4px;
  transition: border-color 0.2s;

  &:hover {
    border: 1px solid ${props => props.theme.colors.borderColor1};
  }
`;

const TitleBarButtons = ({id, onClickMaximise, canMaximise}) => {
  const { removeWindow } = React.useContext(ApplicationContext)
  const {theme, setTheme} = useContext(ThemeContext);

  const onClickClose = (id) => {
    removeWindow(id)
  }

  return(
    <TitleBarButtonsContainer>
      { canMaximise &&
        <TitleButton onClick={onClickMaximise} className='title-bar-button'>
          <Icons.Maximise color={Themes[theme].colors.borderColor1} />
        </TitleButton>
      }
      <TitleButton onClick={()=>{onClickClose(id)}} className='title-bar-button'>
        <Icons.Close color={Themes[theme].colors.borderColor1} />
      </TitleButton>
    </TitleBarButtonsContainer>
  )
}

export default TitleBarButtons