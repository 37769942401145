import { Icons } from '../assets/Icons'

const Applications = {
  FILES: 'Files',
  WORDUP: 'WordUp',
  IMAGINE: 'Imagine',
  DIALOG: 'Dialog',
  PRINT: 'Print',
  GAME: 'Game',
  SETTINGS: 'Settings',
  VAMP: 'Vamp',
}

const getApplicationId = (application) => {
  return Object.values(Applications).indexOf(application);
}

const FileTypes = {
  FOLDER: 'folder',
  IMG: 'img',
  DOCUMENT: 'doc',
  VIDEO: 'video',
}

const FileTypeImages = {
  [FileTypes.FOLDER]: Icons.FolderIcon,
  [FileTypes.IMG]: Icons.PhotoIcon,
  [FileTypes.DOCUMENT]: Icons.DocumentIcon,
  [FileTypes.SETTINGS]: Icons.DocumentIcon,
  [FileTypes.VIDEO]: Icons.VideoIcon,
}

const FileTypeApplications = {
  [FileTypes.FOLDER]: Applications.FILES,
  [FileTypes.IMG]: Applications.IMAGINE,
  [FileTypes.DOCUMENT]: Applications.WORDUP,
  [FileTypes.VIDEO]: Applications.VAMP,
}

const ApplicationGoBarIcons = {
  [Applications.FILES]: Icons.SmallFolderIcon,
  [Applications.IMAGINE]: Icons.PhotoIcon,
  [Applications.WORDUP]: Icons.SmallDocumentIcon,
  [Applications.PRINT]: Icons.PrinterIcon,
  [Applications.GAME]: Icons.JoystickSmall,
  [Applications.SETTINGS]: Icons.SmallDocumentIcon,
  [Applications.VAMP]: Icons.SmallVideoIcon,
}

export { Applications, getApplicationId, FileTypeApplications, FileTypeImages, FileTypes, ApplicationGoBarIcons };