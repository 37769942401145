import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import AnimatedLine from './AnimatedLine';
import AnimatedCharacters from './AnimatedCharacters';

const TerminalContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: left;
  padding: ${props => props.theme.spacing.medium};
  box-sizing: border-box;
  color: ${props => props.theme.colors.terminalTextColor};
  font-family: ${props => props.theme.fonts.terminal};
`

const LineContainer = styled.div`
  font-weight: ${p => p.bold ? 600 : 200};
  min-height: 1.2em;
`

const Terminal = ({
  initialText = [],
  animationTimePerCharacter = 1,
}) => {
  const [text, setText] = useState(initialText);
  const textDelayRef = useRef(0);

  return (
    <TerminalContainer>
      {text.map((line, index) => {
        const delay = line.delay ? parseFloat(line.delay) : 0;
        const delayTime = textDelayRef.current + delay;

        if (line.text || line.emptyLine) {
          textDelayRef.current = (animationTimePerCharacter*line.text.length + delayTime);
          return (
            <LineContainer bold={!!line.bold}>
              <AnimatedLine
                key={index}
                text={line}
                animationTimePerCharacter={animationTimePerCharacter}
                delay={parseInt(delayTime)}
              />
            </LineContainer>
          )
        } else if (line.frames) {
          textDelayRef.current = delayTime;
          return (
            <AnimatedCharacters key={index} frames={line.frames} delay={delayTime} animationSpeed={line.animationSpeed}  />
          )
        }
      })}
    </TerminalContainer>
  )
}

export default Terminal;