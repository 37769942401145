import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//import { SpriteAnimator } from 'react-sprite-animator';
import {
  Animations, AnimationStates
} from './ClipsterStateMachine';

import SpriteSheetAnimator from '../SpriteSheetAnimator/SpriteSheetAnimator';
import { SpeechBubble } from './SpeechBubble';

const ClipsterContainer = styled.div`
  position: absolute;
`

const currentAnimation = Animations.Still;
const currentAnimationState = AnimationStates.animateIn;  

const Clipster = () => {
  const [anim, setAnim] = useState(Animations.Appear);
  const [animState, setAnimState] = useState(null);
  const [animStateIndex, setAnimStateIndex] = useState(0);
  const [speechBubbleOpen, setSpeechBubbleOpen] = useState(false);

  const nextAnimationInState = () => {
    if (!!animState && animStateIndex < animState.animations.length - 1) {
      setAnimStateIndex(animStateIndex+1);
    } else if (!!animState && animState.loop) {
      setAnimStateIndex(0);
    }
  }

  const onEndAnimation = () => {
    if (!!animState) {
      nextAnimationInState();
    } else {
      setAnimState(AnimationStates.passive);
    }
  }

  useEffect(() => {
    if (!!animState) {
      setAnim(animState.animations[animStateIndex])
    }
  }, [animState, animStateIndex])

  return (
    <ClipsterContainer>
      { speechBubbleOpen &&
        <SpeechBubble
          position='top'
          text={"Hi! I'm Clipster!"}
          primaryCtaLabel={'Okay!'}
          primaryCtaOnClick={() => {console.log('woop')}}
        />
      }
      <SpriteSheetAnimator
        duration={anim?.duration || 0}
        frameWidth={240}
        frameHeight={240}
        fps={anim?.frameCount == 0 ? 0 : 8}
        frameCount={anim?.frameCount || 0}
        rowNumber={anim.rowNumber}
        scale={0.5}
        spriteSheet={'https://i.imgur.com/FjGsnVU.png'}
        onEndAnimation={onEndAnimation}
      />
    </ClipsterContainer>
  )
}

export default Clipster;