
import React, { useState, useContext } from 'react';
import Window from '../Window/Window';
import styled from 'styled-components';
import Toolbar from '../Window/Toolbar';
import Network from '../Network';
import { ThemeContext } from '../../contexts/ThemeContext';
import { Themes } from '../../Theme/Theme'
import { IconRenderer, Icons } from '../../assets/Icons';
import rgb2hex from 'rgb2hex';

const SettingsAppContainer = styled.div`
  display: flex;
  height: 100%;
  border-top: 2px solid #555;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const ToolbarContainer = styled.div`
  height: 100%;
  border-right: ${props => props.theme.border.secondary};

  @media screen and (max-width: 500px) {
    height: auto;
  }
`

const SettingsPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  padding: 0 ${props => props.theme.spacing.large};
  box-sizing: border-box;
`

const ThemesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing.medium};
`

const HardwareContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing.medium};
  width: 100%;
  justify-content: center;
  align-items: center;
`

const HardwareList = styled.ul`
  display: flex;
  flex-direction: column;
  border: 2px solid ${props => props.theme.colors.borderColor2};
  border-radius: ${props => props.theme.border.radius.secondary};
  background: ${props => props.theme.backgroundColors.window};
  padding: 0;
  min-width: 320px;
  overflow: hidden;
`

const HardwareListItem = styled.li`
  display: flex;
  padding: 0 20px;
  white-space: nowrap;

  &:nth-child(2n) {
    background: ${props => props.theme.backgroundColors.wordup};
  }
`

const Key = styled.div`
  width: 40%;
`

const Value = styled.div`
  width: 60%;
`

const ThemeButton = styled.button`
  padding: 0;
  border: 0;
  background: 0;
`;

const NetworkPanelContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  @media screen and (max-width: 500px) {
    height: auto;
  }
`

const ThemesPanel = () => {
  const {theme, setTheme} = useContext(ThemeContext);

  return (
    <ThemesContainer>
      { Object.keys(Themes).map((item, i)=>{
          return (
            <ThemeButton key={item+i} onClick={() => setTheme(item)}>
              <Icons.Theme
                bg1={Themes[item].backgroundColors.gradient1}
                bg2={Themes[item].backgroundColors.gradient2}
                windowFill={Themes[item].backgroundColors.window}
                goBarBg={rgb2hex(Themes[item].backgroundColors.goBar).hex}
                goBarBgOpac={rgb2hex(Themes[item].backgroundColors.goBar).alpha}
                strokeColor={Themes[item].colors.borderColor1}
                altStrokeColor='#555555'
                contentColor={Themes[item].colors.textColor}
                icon1Color='#e3e3e3'
                icon2Color='#969696'
              />
            </ThemeButton>
          )})}
    </ThemesContainer>
  )
}

const HardwarePanel = () => (
  <HardwareContainer>
    <IconRenderer Component={Icons.ComputerIcon} width={100} height={100} />
    <HardwareList>
      <HardwareListItem>
        <Key>Processor</Key>
        <Value>233 MHz Ventious II</Value>
      </HardwareListItem>
      <HardwareListItem>
        <Key>Memory</Key>
        <Value>32 MB</Value>
      </HardwareListItem>
      <HardwareListItem>
        <Key>Graphics</Key>
        <Value>ThrusterX-960</Value>
      </HardwareListItem>
      <HardwareListItem>
        <Key>Soundcard</Key>
        <Value>ShockMaster 2.0</Value>
      </HardwareListItem>
      <HardwareListItem>
        <Key>HardDrive</Key>
        <Value>4GB</Value>
      </HardwareListItem>
      <HardwareListItem>
        <Key>Optical Drive</Key>
        <Value>24x CD-ROM drive</Value>
      </HardwareListItem>
      <HardwareListItem>
        <Key>Floppy Drive</Key>
        <Value>3 1/2" Disk Drive Module</Value>
      </HardwareListItem>
    </HardwareList>
  </HardwareContainer>
)

const NetworkPanel = () => {
  return (
    <NetworkPanelContainer>
      <Network />
    </NetworkPanelContainer>
  )
}

const Panels = {
  THEMES: {
    id: 'themes',
    label: 'Themes',
    title: 'Themes',
    component: ThemesPanel,
  },
  HARDWARE: { 
    id: 'hardware',
    label: 'Hardware',
    title: 'Hardware',
    component: HardwarePanel,
  },
  NETWORKING: {
    id: 'networks',
    label: 'Network',
    title: 'Network',
    component: NetworkPanel,
  },
  DEVICES: {
    id: 'devices',
    label: 'Devices',
    title: 'Devices',
    component: () => {},
  },
}

const Settings = ({windowId}) => {
  const [ currentPanel, setCurrentPanel ] = useState(Object.keys(Panels)[0]);

  const SettingsPanels = Object.keys(Panels);
  const screenWidth = window.innerWidth;
  
  return (
    <Window
      id={windowId}
      title={'Settings'}
    >
      <SettingsAppContainer>
        <ToolbarContainer>
          <Toolbar
            vertical={screenWidth > 500}
            buttonWidth={screenWidth > 500 ? 120 : 80}
            buttons={
              SettingsPanels.map(panelKey => {
                const panel = Panels[panelKey];
                return (
                {
                  active: (currentPanel === panelKey),
                  onClick: () => {
                    setCurrentPanel(panelKey)
                  },
                  label: panel.id,
                }
              )
            })
            }
          />
        </ToolbarContainer>
        <SettingsPanelContainer>
          <h2>{ Panels[currentPanel].title }</h2>
          { Panels[currentPanel].component() }
        </SettingsPanelContainer>
      </SettingsAppContainer>
    </Window>
  )
}

export default Settings;