import React from 'react';
import styled from 'styled-components';
import DeepButton from '../Buttons/DeepButton';
import { IconRenderer } from '../../assets/Icons';

const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.$vertical ? 'column' : 'row'};
  flex-wrap: wrap;
  border-bottom:
    2px
    solid
    ${props => props.theme.border.colors.primary};
    margin: ${props => props.$vertical ? '0 0 0 -2px' : '0  0 0 -2px'};
`
//props
//  [{
//    Icon: reactnode,
//    onClick: function,
//    label: String,
//  },{...},...]
const Toolbar = ({buttons, vertical, buttonWidth = 44, buttonHeight = 44}) => {
  return (
    <Container $vertical={vertical}>
      { buttons.map((button, i) => {
        const Icon = button.icon
        return (
          <DeepButton
            key={i}
            index={i}
            label={button.label}
            onClick={button.onClick}
            disabled={!!button.disabled}
            width={buttonWidth}
            height={buttonHeight}
          >
            { Icon ?
              <IconRenderer Component={Icon} className="toolbar-icon" width={22} height={22} />
            :
              <span>{ button.label }</span>
            }
          </DeepButton>
        )
      })}
    </Container>
  )
}

export default Toolbar