import React from "react"
import Network from './Network'
import styled from 'styled-components'

const SmallImage = styled.img`
    max-width: 100%;
    height: auto;
    width: 200px;
`

const ScrollableContent = styled.div`
    overflow-y: scroll;
    height: 100%;
    padding: 20px;
`;

const AboutDialog = {
    title: "About this System",
    //text: "This system is a system",
    height: 500,
    width: 500,
    children: (
        <ScrollableContent>
            <h3>DoorsOS</h3>
            <p>This pseudo-operating system was built by Max Howard and will soon be available on Github</p>
            <p>DoorsOS was written in React. I have tried my best to make this Operating System as data driven as possible. Developers should be able to integrate this web-app with a headless CMS with relative ease.</p>
            <p>The look and feel of this app can be customized either by adjusting theme variables or for more granular control, by editting the components directly. More to come...</p>
        </ScrollableContent>
    )
}

const AboutThisMaxDialog = {
    title: "About this Max",
    //text: "This system is a system",
    height: 500,
    width: 500,
    children: (
        <div>
            <h3>Max Howard</h3>
            <p>Hi. I'm a software developer. I love to build unique web experiences. This is one of them. I used to build websites for Adobe but nowadays I'm working full time at Hootsuite.</p>
            <SmallImage src="https://i.imgur.com/Z4kaYZP.jpeg" alt="Picture of Me" />
            <p>I also like building games, editing videos and doing some remarkably amateur woodworking</p>
            <p>You can reach me in all the usual ways:</p>
            <p>Threads: <a href="https://www.threads.net/@pogomoyo">@pogomoyo</a></p>
            <p>Instagram: <a href="https://www.instagram.com/maxkhoward">@maxkhoward</a></p>
            <p>Email: <a href="mailto:maxkhoward@gmail.com">maxkhoward@gmail.com</a></p>
            <p></p>
        </div>
    )
}

const NetworkDialog = {
    title: "",
    height: 200,
    width: 350,
    children: (
        <Network />
    )
}

export { AboutDialog, AboutThisMaxDialog, NetworkDialog }