import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MainMenu from './MainMenu'
import Button from '../../../components/Buttons/Button'

const ROWS = 24;
const COLS = 24;
const BOARD_WIDTH = 300;
const BOARD_HEIGHT = 300;
const START_X = 4;
const START_Y = 4;
const START_FRAME_DELAY = 150;
const MIN_FRAME_DELAY = 50;
const FRAME_DELAY_CHANGE = 5;

const CellMap = {
    empty: 0,
    snake1: 1,
    snake1_head: 2,
    food: 3,
}

const GameStates = {
    MAIN_MENU: 'main_menu',
    P1_GAME: 'p1_game',
    P1_DEATH: 'pq_death',
}

const Directions = {
    UP: 'up',
    DOWN: 'down',
    LEFT: 'left',
    RIGHT: 'right'
}

const getNewRandomLocation = (avoidArray) => {
    const availableSpaces = Array.from({length: ROWS*COLS}).map((el, i)=>{
        return {x: i % COLS, y: Math.floor(i/COLS)}
    });

    avoidArray.forEach((element) => {
        availableSpaces.splice(availableSpaces.indexOf(availableSpaces.find((e)=>(element.x === e.x && element.y === e.y))),1);
    });

    const randomArrayEntry = availableSpaces[Math.floor(Math.random()*availableSpaces.length)];
    
    return randomArrayEntry;
}

const GameWindow = styled.div`
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-items: center;
   padding: 20px;
   background: ${props => props.theme.window.backgroundColor};
   overflow: hidden;
`;

const GamePanel = styled.div`
    position: relative;
    width: ${BOARD_WIDTH}px;
    height: ${BOARD_HEIGHT}px;
    border: ${props => props.theme.border.size.primary} solid ${props => props.theme.border.colors.primary};
    & > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`

const BackgroundGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${COLS}, 1fr);
    grid-template-rows: repeat(${ROWS}, 1fr);
    width: ${BOARD_WIDTH}px;
    height: ${BOARD_HEIGHT}px;
    background: #eaeaea;
    gap: 1px;
    overflow: hidden;
`

const GameGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${COLS}, 1fr);
    grid-template-rows: repeat(${ROWS}, 1fr);
    width: ${BOARD_WIDTH}px;
    height: ${BOARD_HEIGHT}px;
    background: transparent;
    overflow: hidden;
`

const SnakeBody = styled.div.attrs(props => ({
    style: {
        gridColumnStart: props.x+1,
        gridColumnEnd: props.x+2,
        gridRowStart: props.y+1,
        gridRowEnd: props.y+2,
        // borderTopRightRadius: props.btr && '50%',
        // borderBottomRightRadius: props.bbr && '50%',
        // borderBottomLeftRadius: props.bbl && '50%',
        // borderTopLeftRadius: props.btl && '50%',
    }
}))`
    border-radius: 4px;
    background: #85cba6;
`;

const Food = styled.div`
    grid-column-start: ${props => props?.x+1};
    grid-column-end: ${props => props?.x+2};
    grid-row-start: ${props => props?.y+1};
    grid-row-end: ${props => props?.y+2};
    width: calc(BOARD_WIDTH/${COLS});
    height: calc(BOARD_HEIGHT/${ROWS});
    background: #937dd3;
    border-radius: 100%;
`

const InfoPanel = styled.div`
    width: ${BOARD_WIDTH}px;
    height: ${BOARD_HEIGHT}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #c75252;
    font-weight: bold;
    background: rgba(255,255,255,0.5);
`

const TextButtonsContainer = styled.div`
    width: 200px;
    text-align: center;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 8px;
`

const SnakeGame = ({passThroughWalls}) => {
    const [gameState, _setGameState] = useState(GameStates.MAIN_MENU);
    const gameStateRef = useRef(gameState);
    const setGameState = data => {
        gameStateRef.current = data;
        _setGameState(data);
    }

    const [snakeArray, setSnakeArray] = useState([]);

    //Refactor by converting all the following useStates to useRefs to prevent rerender
    const [frameDelay, setFrameDelay] = useState(START_FRAME_DELAY);
    const [food, setFood] = useState({});
    const [count, setCount] = useState(0);
    const [growSnake, setGrowSnake] = useState(false);
    
    const [paused, _setPaused] = useState(false);
    const pausedRef = useRef(paused);
    const setPaused = func => {
        const value = func(pausedRef.current);
        pausedRef.current = value;
        _setPaused(value);
    }
    const [directionQueue, _setDirectionQueue] = useState([]);
    const [direction, _setDirection] = useState('right');
    const directionRef = useRef(direction);
    const setDirection = data => {
        directionRef.current = data;
        _setDirection(data);
    }
    const directionQueueRef = useRef(directionQueue);
    const setDirectionQueue = data => {
        directionQueueRef.current = data;
        _setDirectionQueue(data);
    }

    const StartGame = () => {
        setSnakeArray([{x:5,y:4},{x:4,y:4},{x:3,y:4},{x:2,y:4},{x:1,y:4}]);
        setCount(0);
        setDirection('right');
        setDirectionQueue([]);
        setFood(getNewRandomLocation(snakeArray));
        setGameState(GameStates.P1_GAME);
        setPaused(() => false);
    }

    const EndGame = () => {
        setGameState(GameStates.P1_DEATH);
        setSnakeArray([])
        setFrameDelay(START_FRAME_DELAY);
        setFood({})
    }

    const GoToMainMenu = () => {
        setGameState(GameStates.MAIN_MENU);
        setSnakeArray([])
        setFrameDelay(START_FRAME_DELAY);
        setFood({})
    }

    // Init
    useEffect(()=>{
        document.addEventListener('keydown',OnKeyDown);
        return () => document.removeEventListener('keydown',OnKeyDown);
    }, []);

    // Game Loop
    useEffect(() => {
        // This interval triggers the loop refresh
        const interval = setInterval(() => {
            setCount(oldCount => oldCount + 1);
        }, frameDelay);

        if (count > 0 && !paused && gameState==GameStates.P1_GAME) {
            if (directionQueue.length > 0) {
                setDirection(directionQueue[0])
                setDirectionQueue(
                    [...directionQueue.slice(1)]
                );
            }
            setSnakeArray(oldSnakeArray => {
                const newHeadPosition = {x: oldSnakeArray[0].x, y: oldSnakeArray[0].y};
                
                const snakeTailLength = growSnake ?
                    oldSnakeArray.length :
                    oldSnakeArray.length - 1;
                
                switch (directionRef.current) {
                    case Directions.UP:     newHeadPosition.y--; break;
                    case Directions.DOWN:   newHeadPosition.y++; break;
                    case Directions.LEFT:   newHeadPosition.x--; break;
                    case Directions.RIGHT:  newHeadPosition.x++; break;
                }

                if (growSnake) {
                    setGrowSnake(false);
                }
                
                return ([
                    newHeadPosition,
                    ...oldSnakeArray.slice(0,snakeTailLength)
                ])
            })

            // Check for Food Collision
            if (snakeArray[0]?.x === food?.x && snakeArray[0]?.y === food?.y) {
                setFood(getNewRandomLocation(snakeArray));
                if (frameDelay > MIN_FRAME_DELAY) {
                    setFrameDelay(oldFrameDelay => oldFrameDelay-FRAME_DELAY_CHANGE);
                }
                setGrowSnake(true);
            }

            //Check for Wall Collision
            if (snakeArray[0]?.x === COLS || snakeArray[0]?.x === -1 || snakeArray[0]?.y === ROWS || snakeArray[0]?.y === -1) {
                EndGame();
                if (interval) clearInterval(interval);
            }

            //Check for tail collision
            if (snakeArray?.slice(1).some((e) => e.x === snakeArray[0].x && e.y === snakeArray[0].y)) {
                EndGame();
                if (interval) clearInterval(interval);
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        }
    },[count])

    const OnKeyDown = (e) => {
        let nextDirection = ''
        let lastDirection = directionQueueRef.current.length > 0 ?
            directionQueueRef.current[0] :
            directionRef.current;

        switch (e.key) {
            case 'ArrowUp':
                if (lastDirection === Directions.LEFT || lastDirection === Directions.RIGHT) {
                    nextDirection = Directions.UP;
                }
                break;
            case 'ArrowDown':
                if (lastDirection === Directions.LEFT || lastDirection === Directions.RIGHT) {
                    nextDirection = Directions.DOWN;
                }
                break;
            case 'ArrowLeft':
                if (lastDirection === Directions.UP || lastDirection === Directions.DOWN) {
                    nextDirection = Directions.LEFT;
                }
                break;
            case 'ArrowRight':
                if (lastDirection === Directions.UP || lastDirection === Directions.DOWN) {
                    nextDirection = Directions.RIGHT;
                }
                break;
            case 'Enter':
                //if (gameStateRef === GameStates.P1_GAME){
                    setPaused(oldPaused => !oldPaused);
                //}
                break;
            default:
                break;
        }

        if (nextDirection !== '') {
            if (directionQueueRef.current.length > 0) {
                setDirectionQueue([
                    directionQueueRef.current[0],
                    nextDirection
                ])
            } else {
                setDirectionQueue([nextDirection])
            }
        }
    }

    useEffect(() => {
        
    }, [direction])

    return (
        <GameWindow>
            <h2>Snack</h2>
            { gameState === GameStates.MAIN_MENU ?
                <MainMenu
                    startGame={() => StartGame()}
                />
                :
                <GamePanel>
                    <BackgroundGrid>   
                        <Food className='food-item'
                            x={food.x}
                            y={food.y}
                        />
                    </BackgroundGrid>
                    <GameGrid>
                        { snakeArray.map((cell, i) => (
                            <SnakeBody className='snake_p1'
                                
                                btr={i == 0 && (direction===Directions.UP || direction === Directions.RIGHT) }
                                bbr={i == 0 && (direction===Directions.DOWN || direction === Directions.RIGHT) }
                                bbl={i == 0 && (direction===Directions.DOWN || direction === Directions.LEFT) }
                                btl={i == 0 && (direction===Directions.UP || direction === Directions.LEFT) }
                                x={cell.x}
                                y={cell.y}
                            />
                        )) }
                    </GameGrid>
                    { gameState === GameStates.P1_DEATH &&
                        <InfoPanel>
                            <TextButtonsContainer>
                                <h3>Your Snake Snacked on itself</h3>
                                <Button onClick={() => StartGame()}>
                                    Restart
                                </Button>
                                <Button onClick={() => GoToMainMenu()}>
                                    Main Menu
                                </Button>
                            </TextButtonsContainer>
                        </InfoPanel>
                    }
                    { gameState === GameStates.P1_GAME && paused &&
                        <InfoPanel>
                            <TextButtonsContainer>
                                <h3>PAUSED</h3>
                                <Button onClick={() => StartGame()}>
                                    Restart
                                </Button>
                                <Button onClick={() => GoToMainMenu()}>
                                    Main Menu
                                </Button>
                            </TextButtonsContainer>
                        </InfoPanel>
                    }
                </GamePanel>
            }
        </GameWindow>
    )
}

export default SnakeGame;