import React, { useContext, useEffect, useState } from 'react'
import { ApplicationContext } from '../../contexts/ApplicationContext'
import styled from 'styled-components'
import Emitter from '../../utils/Emitter'
import events from '../../constants/events'
import { Applications, FileTypes, FileTypeApplications, FileTypeImages } from '../../constants/applications'
import { WindowKeys } from '../../contexts/ApplicationContext'
import Files from '../AppViews/Files'
import Dialog from '../AppViews/Dialog'
import Imagine from '../AppViews/Imagine'
import WordUp from '../AppViews/WordUp'
import Print from '../AppViews/Print'
import Game from '../AppViews/Game'
import Settings from '../AppViews/Settings'
import Vamp from '../AppViews/Vamp'

const WindowsContainer = styled.div`
  z-index: 2;
`

const WindowZIndexContainer = styled.div`
  position: relative;
  //z-index: ${props => props.active ? 2 : 1};
`

const WindowManager = () => {
  const { windows, addWindow, activeWindow, windowOrder } = useContext(ApplicationContext)
  //const [ windows, setWindows ] = useState([]);


  useEffect(()=>{
  },[windowOrder])

  useEffect(()=>{
    const OpenWindow = (eventData) => {
      const application = FileTypeApplications[eventData.type] || eventData.type;
      const data = eventData.data || {}
      data.id = eventData.id
      const title = eventData.title
      const id = Object.values(Applications).indexOf(application)
      const newWindow = {
        [WindowKeys.APPLICATION]: application,
        [WindowKeys.ID]: id,
        [WindowKeys.DATA]: data,
        [WindowKeys.TITLE]: title,
      }
      addWindow(newWindow);
    }
    Emitter.on(events.OPEN_WINDOW, OpenWindow)
    return () => Emitter.off(events.OPEN_WINDOW, OpenWindow)
  },[])
  
  const renderApplications = (application, id, data, title) => {
    switch(application) {
      case Applications.FILES:
        return (
          <Files
            key={id}
            windowId={id}
            directoryId={data.id}
            directoryData={data}
          /> 
        )
        break;
      case Applications.WORDUP:
        return (
          <WordUp
            key={id}
            windowId={id}
            fileName={title}
            blogId={data.blogId}
          />
        )
      case Applications.IMAGINE:
        return (
          <Imagine
            key={id}
            windowId={id}
            fileName={title}
            imgUrl={data.imgUrl}
            imgurAlbumId={data.imgurAlbumId}
          />
        )
      case Applications.PRINT:
        return (
          <Print
            key={id}
            windowId={id}
            fileName={title}
          />
        )
      case Applications.GAME:
        return (
          <Game
            key={id}
            windowId={id}
            fileName={title}
          />
        )
      case Applications.SETTINGS:
        return (
          <Settings
            key={id}
            windowId={id}
          />
        )
      case Applications.VAMP:
        return (
          <Vamp
            key={id}
            windowId={id}
            fileName={title}
            src={data.src}
            aspectRatio={data.aspectRatio}
          />
        )
      default:
        return (
          <Dialog key={id} windowId={id} {...data} />
        )
    } 
  }
    
  return (
    <WindowsContainer>
      { windowOrder.map(windowId => {
        const window = windows.find(window=>window.id===windowId)
        if (window) {
          return (
            <WindowZIndexContainer key={windowId}>
              {renderApplications(
                window[WindowKeys.APPLICATION],
                window[WindowKeys.ID],
                window[WindowKeys.DATA],
                window[WindowKeys.TITLE],
              )}
            </WindowZIndexContainer>
          )
        }
      })}
    </WindowsContainer>
  )
}

export default WindowManager