import React, { createContext, useState, useEffect } from 'react'
import { Applications } from '../constants/applications'

const WindowKeys = {
  APPLICATION: 'application',
  ID: 'id',
  PATH: 'path',
  TITLE: 'title',
  DATA: 'data',
  ACTIVE: 'active',
}

const ApplicationsArray = Object.values(Applications);

const ApplicationContext = createContext(null)

const ApplicationProvider = ({children}) => {
  const [windows, setWindows] = useState([])
  const [activeWindow, setAW] = useState(0)
  const [windowOrder, setWindowOrder] = useState([])
  // [2,4,1,0,3]

  const addWindow = (window) => {
    const windowIndex = ApplicationsArray.indexOf(window.application);

    setActiveWindow(windowIndex)

    setWindows((prevState) => {
      const windowIndex = prevState.findIndex(e => e.application === window.application);
      if (windowIndex > -1) {
        prevState[windowIndex].title = window.title
        prevState[windowIndex].data = window.data
      }
      const newWindow = windowIndex > 1 ?
        prevState[windowIndex] :
        window
      
      return([
        ...prevState.filter((el) => el.application !== window.application),
        newWindow
      ])
    })
  }

  const removeWindow = (id = -1) => {
    if (id < 0) {
      id = activeWindow
    }
    setWindowOrder((prevState) => (
      [...prevState.filter(e => e !== id)]
    ))
    setWindows((prevState) => prevState.filter((window) => (
      window.id !== id
    )))
  }

  const setActiveWindow = (id) => {
    setWindowOrder((prevState) => {
      return [...prevState.filter(e => e!==id),id]
    })
    // setWindows((prevState) => [
    //   ...prevState.filter((window) => window.id !== id),
    //   ...prevState.filter((window) => window.id=== id)
    // ])
  }
  
  return (
    <ApplicationContext.Provider value={{ windows, addWindow, removeWindow, activeWindow, setActiveWindow, windowOrder }}>
      { children }
    </ApplicationContext.Provider>
  )
}

export { ApplicationContext, ApplicationProvider, WindowKeys }