import React, { useContext } from "react";
import { ThemeProvider } from "styled-components";
import { ThemeContext } from '../contexts/ThemeContext';

//define a base theme. This should be the core values such as border radius's etc.
//other values that should be able to be customised in app should exist in different theme overides.
//These theme overrides should be exported from here and used to build up the themes settings page.

const lightTheme = {
  border: {
    radius: {
      primary: '16px',
      secondary: '8px',
    },
    size: {
      primary: '3px',
      secondary: '1px',
    },
    colors: {
      primary: '#555555',
      secondary: 'rgba(0,0,0,0.2)',
    },
    primary: '3px solid #555',
    secondary: '2px solid #555',
    vampInput: '2px solid #9E9E9E',
  },
  files: {
    activeColor: 'rgba(255,255,255,0.9)',
    focusColor: 'rgba(255,255,255,0.6)',
  },
  goBar: {
    height: '42px',
  },
  window: {
    topbar: {
      height: '48px',
      fontSize: '18px',
      color: '#555555',
      backgroundColor: '#F8F8F8',
    },
    backgroundColor: '#F8F8F8',
  },
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
  buttons: {
    buttonSizeSmall: '42px',
    buttonTextColor: '#333333',
    buttonTextActiveColor: '#797979'
  },
  backgroundGradient: `linear-gradient(222deg, #FBD9F7 24.3%, #D2F7FF 79.32%)`,
  colors: {
    textColor: '#333333',
    linkColor: '#bc4eaf',
    borderColor1: '#555555',
    borderColor2: '#555555',
    highlightColor: '#fcf5c7',
    fileFocusColor: 'rgba(255,255,255,0.6)',
    fileActiveColor: 'rgba(255,255,255,0.9)',
    logo1: '#D2F7FF',
    logo2: '#FBD9F7',
    terminalTextColor: '#fff',
    shutdownTextColor: '#e8a761',
    vampIconColor: '#333333',
  },
  backgroundColors: {
    imagine: '#777',
    overlay: '#222',
    wordup: '#d1d1d1',
    wordupPage: '#FFFFFF',
    window: '#F8F8F8',
    gradient1: '#FBD9F7',
    gradient2: '#D2F7FF',
    goBar: 'rgba(255,255,255,0.3)',
    button: '#F8F8F8',
    vamp: '#222',
    vampControlsGradient1: '#f0f0f0',
    vampControlsGradient2: '#c5c5c5',
  },
  desktopBackgrounds: {
    bgColor1: "#F292E9",
    bgColor2: "#80E8FF",
    hill1Color: "#E3DDF3",
    hill2Color: "#D8DBF0",
    hill3Color: "#D6D4EB",
    hill4Color: "#CDCBE5",
    fillOpacity: "0.35",
  },
  settings: {
    monochrome: false,
  },
  fonts: {
    primary: '"Lexend", "sans-serif", "Roboto"',
    terminal: '"Roboto Mono", monospace',
  },
  fontSizes: {
    large: "24px",
    medium: "16px",
    small: "14px",
    xsmall: "12px",
    tiny: "10px",
  }
};

const darkTheme = JSON.parse(JSON.stringify(lightTheme));
darkTheme.border.vampInput = '2px solid #838383';
darkTheme.colors.textColor = '#e4e4e4';
darkTheme.colors.borderColor1 = '#C8C8C8';
darkTheme.colors.borderColor2 = '#555555';
darkTheme.colors.highlightColor = '#3F295C';
darkTheme.colors.fileFocusColor = 'rgba(207, 207, 207, 0.3)';
darkTheme.colors.fileActiveColor = 'rgba(161, 161, 161, 0.3)';
darkTheme.colors.logo1 = '#ad7eb5';
darkTheme.colors.logo2 = '#382f5a';
darkTheme.backgroundColors.gradient1 = '#9C5EA6'
darkTheme.backgroundColors.gradient2 = '#1E1541'
darkTheme.backgroundColors.window = '#292929'
darkTheme.backgroundColors.imagine = '#292929'
darkTheme.backgroundColors.wordup = '#292929'
darkTheme.backgroundColors.wordupPage = '#343434'
darkTheme.backgroundColors.button = '#393939'
darkTheme.backgroundColors.goBar = 'rgba(41,41,41,0.85)'
darkTheme.backgroundGradient = `linear-gradient(222deg, ${darkTheme.backgroundColors.gradient1} 24.3%, ${darkTheme.backgroundColors.gradient2} 79.32%)`
darkTheme.colors.vampIconColor = '#9E9E9E';
darkTheme.backgroundColors.vampControlsGradient1 = '#363636';
darkTheme.backgroundColors.vampControlsGradient2 = '#2c2c2c';
darkTheme.desktopBackgrounds.bgColor1 = "#1E1541";
darkTheme.desktopBackgrounds.bgColor2 = "#9C5EA6";
darkTheme.desktopBackgrounds.hill1Color = "#896B98";
darkTheme.desktopBackgrounds.hill2Color = "#765984";
darkTheme.desktopBackgrounds.hill3Color = "#6B4D7A";
darkTheme.desktopBackgrounds.hill4Color = "#573B64";
darkTheme.desktopBackgrounds.fillOpacity = "1.0";

const lightMonoTheme = JSON.parse(JSON.stringify(lightTheme));
lightMonoTheme.colors.textColor = '#767676';
lightMonoTheme.colors.borderColor1 = '#767676';
lightMonoTheme.colors.highlightColor = '#d1d1d1';
lightMonoTheme.colors.fileFocusColor = 'rgba(207, 207, 207, 0.6)';
lightMonoTheme.colors.fileActiveColor = 'rgba(161, 161, 161, 0.9)';
lightMonoTheme.backgroundColors.gradient1 = '#D3D3D3'
lightMonoTheme.backgroundColors.gradient2 = '#FFFFFF'
lightMonoTheme.backgroundColors.window = '#E9E9E9'
lightMonoTheme.backgroundColors.goBar = 'rgba(233,233,233,0.31)'
lightMonoTheme.backgroundGradient = `linear-gradient(222deg, ${lightMonoTheme.backgroundColors.gradient1} 24.3%, ${lightMonoTheme.backgroundColors.gradient2} 79.32%)`
lightMonoTheme.settings.monochrome = true;
lightMonoTheme.desktopBackgrounds.bgColor1 = "#bcbcbc";
lightMonoTheme.desktopBackgrounds.bgColor2 = "#d4d4d4";
lightMonoTheme.desktopBackgrounds.hill1Color = "#e0e0e0";
lightMonoTheme.desktopBackgrounds.hill2Color = "#dedede";
lightMonoTheme.desktopBackgrounds.hill3Color = "#d8d8d8";
lightMonoTheme.desktopBackgrounds.hill4Color = "#c8c8c8";
lightMonoTheme.desktopBackgrounds.fillOpacity = "0.35";

const darkMonoTheme = JSON.parse(JSON.stringify(lightTheme));
darkMonoTheme.colors.textColor = '#D3D3D3';
darkMonoTheme.colors.borderColor1 = '#D6D6D6';
darkMonoTheme.colors.highlightColor = '#4b4b4b';
darkMonoTheme.colors.fileFocusColor = 'rgba(207, 207, 207, 0.3)';
darkMonoTheme.colors.fileActiveColor = 'rgba(161, 161, 161, 0.3)';
darkMonoTheme.colors.logo1 = '#ad7eb5';
darkMonoTheme.colors.logo2 = '#382f5a';
darkMonoTheme.backgroundColors.gradient1 = '#787878'
darkMonoTheme.backgroundColors.gradient2 = '#363636'
darkMonoTheme.backgroundColors.window = '#353535'
darkMonoTheme.backgroundColors.wordup = '#353535'
darkMonoTheme.backgroundColors.wordupPage = '#414141'
darkMonoTheme.backgroundColors.button = '#393939'
darkMonoTheme.backgroundColors.goBar = 'rgba(26,26,26,0.26)'
darkMonoTheme.backgroundGradient = `linear-gradient(222deg, ${darkMonoTheme.backgroundColors.gradient1} 24.3%, ${darkMonoTheme.backgroundColors.gradient2} 79.32%)`
darkMonoTheme.settings.monochrome = true;
darkMonoTheme.colors.vampIconColor = '#9E9E9E';
darkMonoTheme.backgroundColors.vampControlsGradient1 = '#363636';
darkMonoTheme.backgroundColors.vampControlsGradient2 = '#2c2c2c';
darkMonoTheme.desktopBackgrounds.bgColor1 = "#232323";
darkMonoTheme.desktopBackgrounds.bgColor2 = "#747474";
darkMonoTheme.desktopBackgrounds.hill1Color = "#707070";
darkMonoTheme.desktopBackgrounds.hill2Color = "#727272";
darkMonoTheme.desktopBackgrounds.hill3Color = "#5b5b5b";
darkMonoTheme.desktopBackgrounds.hill4Color = "#3e3e3e";
darkMonoTheme.desktopBackgrounds.fillOpacity = "1.0";

export const Themes = {
  LIGHTTHEME: lightTheme,
  DARKTHEME: darkTheme,
  LIGHTMONOTHEME: lightMonoTheme,
  DARKMONOTHEME: darkMonoTheme,
}

const Theme = ({ children }) => {
  const {theme, setTheme} = useContext(ThemeContext)
  
  return (
    <ThemeProvider theme={Themes[theme]}>{children}</ThemeProvider>
  )
};

export default Theme;
