import React, { useState } from 'react';
import Window from '../Window/Window';
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';
import { BlogPosts } from '../../Data/MockCmsData';
import { Icons } from '../../assets/Icons';
import Clipster from '../Clipster/Clipster';
import Toolbar from '../Window/Toolbar';
import Emitter from '../../utils/Emitter';
import events from '../../constants/events';
import { Applications, FileTypes } from '../../constants/applications';
import Draggable from 'react-draggable';

const PageContainer = styled.div`
  background: ${props => props.theme.backgroundColors.wordup};
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
`

const PageContent = styled.div`
  background: ${props => props.theme.backgroundColors.wordupPage};
  max-width: 650px;
  margin: 0 auto;
  padding: 40px;
  min-height: 400px;
  box-shadow: 1px 1px 8px rgba(0,0,0,0.6);
  text-align: left;

  & img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
`

const BlogList = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  overflow-y: scroll;
`;

const BlogListItem = styled.button`
  overflow: hidden;
  border-radius: 8px;
  background: #fafafa;
  border: 2px solid #333;
  padding: 0;
  height: 260px;
  width: 160px;
  box-shadow: 1px 1px 0px rgba(0,0,0,0.35);
  transition: box-shadow 0.3s, transform 0.2s;

  &:hover {
    transform: translate3d(4px, 4px, 0);
    box-shadow: -3px -3px 0px rgba(0,0,0,0.35);
  }

  &:active {
    transform: translate3d(-1px, -1px, 0);
    box-shadow: -3px -3px 0px rgba(0,0,0,0);
  }
`;

const BlogListPreview = styled.div`
  height: 0;
  padding-bottom: 78%;
  background-image: url(${props => props.imgSrc});
  background-size: cover;
  background-position: center;
`;

const BlogListContent = styled.div`
  height: 96px;
  padding: 0 ${props => props.theme.spacing.small};
  overflow: hidden;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`

const BlogListTitle = styled.h3`
  text-align: left;
  font-size: ${props => props.theme.fontSizes.small};
  margin-bottom: 4px;
`;

const BlogListCopy = styled.p`
  font-size: ${props => props.theme.fontSizes.xsmall};
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
`

const BlogListMeta = styled.div`
  border-top:
    ${props => props.theme.border.size.secondary}
    solid
    ${props => props.theme.border.colors.secondary};
  font-size: ${props => props.theme.fontSizes.tiny};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  padding-left: 8px;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  max-height: 100%;
  border-top: 2px solid #555;
`;

const ClipsterWrapper = styled.div`
  position: absolute;
`

const WordUp = ({windowId, fileName, blogId}) => {
  const [clipsterOpenState, setClipsterOpenState] = useState(false);
  
  const BlogPost = BlogPosts.find((blogPost)=>(
    blogPost.postId === blogId
  ));

  const openView = blogId === undefined;

  return (
    <Window
      width={800}
      id={windowId}
      title={fileName}
      Icon={Icons.SmallDocumentIcon}
    >
      { openView ?
        <PageContainer>
          <BlogList>
            { BlogPosts.map((blog) => {
              if (!blog.hidden) {
                return (
                  <BlogListItem key={blog.postId} onClick={() => {
                    Emitter.emit(events.OPEN_WINDOW, {
                      id: windowId,
                      type: FileTypes.DOCUMENT,
                      title: blog.title,
                      data: { blogId: blog.postId },
                    })}}>
                    <BlogListPreview imgSrc={blog.titleImg}>
                    </BlogListPreview>
                    <BlogListContent>
                      <BlogListTitle>
                        { blog.title }
                      </BlogListTitle>
                      <BlogListCopy>
                        { blog.subTitle }
                      </BlogListCopy>
                    </BlogListContent>
                    <BlogListMeta>
                      { blog.timestamp }
                    </BlogListMeta>
                  </BlogListItem>
                )
              }
            })}
          </BlogList>
        </PageContainer>
        :
        <AppContainer>
          <Toolbar
            buttons={[
              {
                icon: Icons.SmallFolderIcon,
                disabled: false,
                onClick: () => {
                  Emitter.emit(events.OPEN_WINDOW, {
                    id: 0,
                    type: FileTypes.DOCUMENT,
                    title: 'WordUp - Open Doc',
                    data: {},
                  })},
                label: 'See all blogs'
              },
              {
                icon: Icons.PrinterIcon,
                disabled: false,
                onClick: () => {
                  Emitter.emit(events.OPEN_WINDOW, {
                    id: 99,
                    type: Applications.PRINT,
                    title: fileName,
                    data: {},
                  })},
                label: 'See all blogs'
              },
              {
                icon: Icons.FloppyDiskIcon,
                disabled: true,
                onClick: () => {
                  Emitter.emit(events.OPEN_WINDOW, {
                    id: 1,
                    type: FileTypes.DOCUMENT,
                    title: 'WordUp - Open Doc',
                    data: {},
                  })},
                label: 'See all blogs'
              },
              {
                icon: Icons.SmallDocumentIcon,
                disabled: true,
                onClick: () => {
                  Emitter.emit(events.OPEN_WINDOW, {
                    id: 1,
                    type: FileTypes.DOCUMENT,
                    title: 'WordUp - Open Doc',
                    data: {},
                  })},
                label: 'See all blogs'
              },
              {
                disabled: false,
                onClick: () => {
                  setClipsterOpenState(!clipsterOpenState)  
                },
                label: '📎'
              },
            ]}
          />
          <PageContainer>
            { clipsterOpenState &&
              <Draggable bounds="parent">
                <ClipsterWrapper>
                  <Clipster />
                </ClipsterWrapper>
              </Draggable>
            }
            <PageContent>
              <Markdown children={BlogPost.content} />
            </PageContent>
          </PageContainer>
        </AppContainer>
      }
    </Window>
  )
}

export default WordUp;