import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledLine = styled.div`
`;

const AnimatedLine = ({ text, animationTimePerCharacter, delay }) => {
  const [animatedText, setAnimatedText] = useState('');
  
  useEffect(() => {
    let timeoutId;

    const animateText = (index) => {
      if (index < text.text.length) {
        timeoutId = setTimeout(() => {
          setAnimatedText((prevText) => prevText + text.text[index]);
          animateText(index + 1);
        }, animationTimePerCharacter);
      }
    };

    // Start animation after delay
    timeoutId = setTimeout(() => {
      animateText(0);
    }, delay);

    // Clean up timeout when component unmounts
    return () => clearTimeout(timeoutId);
  }, [text, animationTimePerCharacter, delay]);

  return <StyledLine>{animatedText}</StyledLine>;
};

export default AnimatedLine;