import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IconRenderer, Icons } from '../../../assets/Icons'
import Button from '../../Buttons/Button';
import Emitter from '../../../utils/Emitter';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { Applications, getApplicationId } from '../../../constants/applications';

const StyledMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const MenuStates = {
    MAIN_MENU: 'main',
    OPTIONS: 'options',
}

const MainMenu = ({startGame}) => {
    const [menuState, setMenuState] = useState(MenuStates.MAIN_MENU);
    const { removeWindow } = React.useContext(ApplicationContext);

    return (
        <StyledMenu>
            <IconRenderer Component={Icons.Snake} />
            { menuState === MenuStates.MAIN_MENU ?
                <>
                    <Button onClick={startGame}>
                        Start Game
                    </Button>
                    {/* <Button onClick={() => setMenuState(MenuStates.OPTIONS)}>
                        Options
                    </Button> */}
                    <Button onClick={() => removeWindow(getApplicationId(Applications.GAME))}>
                        Exit Game
                    </Button>   
                </>
            :   <>
                    <h3>Options</h3>
                    <Button onClick={() => setMenuState(MenuStates.MAIN_MENU)}>
                        Back
                    </Button>
                </>
            }
        </StyledMenu>
    )
}

export default MainMenu;